.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;

  .head {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: white;

    .close {
      padding-right: 25px;
      margin-left: -10px;
      background: url(/assets/images/back.svg) center center no-repeat;
      width: 20px;
      height: 20px;
    }
  }

  .input {
    border: none;
    border-radius: 5px;
    flex: 1;
    font-size: 16px !important;
    //box-shadow: 0 0 10px $shadow-xl;
    margin: 5px 0;
    outline: none;
    padding: 15px;
  }

  .autocomplete {
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 60px;
    max-height: 45%;

    .item {
      min-height: 60px;
    }
  }
}