@import "functions";
@import "variables";

@include font-face(Montserrat, "/assets/fonts/Montserrat", null, null, ttf);

html, body {
  position: fixed;
  overflow: hidden;
}

html {
  font-size: $font-size;
  color: $text;
}

body {
  font-family: $font, serif;
  margin: 0;
  padding: 0;
  font-size: if($TYPE == "tcl", 1.4rem, 0.87em);
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
}

#app {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100vw;
  height: 100vh;

  @media screen and (max-width: $break-small) {
    flex-direction: column-reverse;
  }
}

.container {
  display: flex;

  /*@media screen and (max-width: $break-small) {
    .board {
      height: 100vh; // Remove this to avoid content to be the same size everywhere

      &.noModule {
        height: auto;
      }
    }
  }*/
}

.flex {
  align-items: center;
  display: flex;
}

.padding {
  padding: 20px;
}

.paddingTop {
  padding-top: 20px;
}

.paddingHorizontal {
  padding: 0 20px;
}

.lightBold {
  font-size: 1em;
  font-weight: 500;
  padding: 5px 0;
}

.bold {
  font-size: 1.3em;
  font-weight: 700;
}

.hide {
  display: none;
}

@import "board";
@import "map";
@import "modal";

// Global plugins overrides
.cluster {
  div {
    background-color: $cluster;
    border-radius: 10px !important;
    box-shadow: 0 0 2px $shadow-xs;
    font-size: 8px !important;
    font-weight: 300 !important;
    left: 17px !important;
    line-height: initial !important;
    padding: 2px 5px !important;
    top: 12px !important;
    width: auto !important;
    z-index: -1;
  }
}

.slider-control-bottomcenter {
  top: -23px;

  li button {
    border-color: initial;
    font-size: 16px !important;
    outline: none;
    padding: 0 2px !important;
  }
}

.slider-slide {
  display: flex !important;
  min-height: 100px;
}

.click-outside {
  display: inline-block;
}

.notification {
  z-index: 9999;
}

.onlyPrint {
  display: none;
}

span.printHide {
  display: flex;
  align-items: center;
}

@media print {
  .printHide {
    display: none
  }
  .onlyPrint {
    display: block !important;
  }
}
