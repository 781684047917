.map {
  flex: 1;
}

.mapContainer {
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.infobox {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 2px 10px $shadow-xs;
  display: flex;
  flex-direction: column;
  font-family: $font, serif;
  font-size: 1.3em;
  margin: 6px;
  position: relative;
  overflow: hidden;

  &.board {
    border-radius: 10px;
    width: 150px;
    cursor: pointer;
  }

  .infoTitle {
    align-items: center;
    background-color: transparent;
    border-bottom: 1px solid $shadow-xs;
    color: $text;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    padding: 10px;
    white-space: nowrap;

    .toolRouteCalculation {
      margin-left: 10px;

      @if ($TYPE == "tcl") {
        width: 20px;
        margin: -6px -6px -6px 10px;
      }
    }
  }

  .infoContent {
    white-space: nowrap;

    .infoboxLines {
      display: grid;
      padding: 5px;

      .infoboxLine {
        cursor: pointer;
        padding: 5px;
        text-align: center;
        display: flex;
        justify-content: center;

        .tad {
          background: url(/assets/images/tad.svg) no-repeat center;
          width: 60px;
          height: 20px;
          background-size: cover;
          border: 1px solid $border-m;
        }

        .tad-0 {
          width: 100px;
          height: 40px;
          background: url(/assets/images/tad-0.svg) no-repeat center;
        }

        .tad-1 {
          width: 100px;
          height: 40px;
          background: url(/assets/images/tad-1.svg) no-repeat center;
        }

        .infoboxLineCode {
          align-self: center;
          border-radius: 2px;
          cursor: pointer;
          font-weight: 500;
          padding: 5px;
          flex: 1;

          &.longLineCode {
            letter-spacing: -1.8px;
          }
        }
      }

      grid-template-columns: repeat(4, 1fr);
    }

    .infoboxTads {
      display: grid;
      padding: 5px;

      .infoboxLine {
        cursor: pointer;
        padding: 5px;
        text-align: center;
        display: flex;
        justify-content: left;

        .tad {
          background: url(/assets/images/tad.svg) no-repeat center;
          width: 60px;
          height: 20px;
          background-size: cover;
          border: 1px solid $border-m;
        }

        .tad-0 {
          width: 175px;
          height: 42px;
          background: url(/assets/images/tad-0.svg) no-repeat center;
        }

        .tad-1 {
          width: 175px;
          height: 42px;
          background: url(/assets/images/tad-1.svg) no-repeat center;
        }

        .infoboxLineCode {
          align-self: center;
          border-radius: 2px;
          cursor: pointer;
          font-weight: 500;
          padding: 5px;
          flex: 1;

          &.longLineCode {
            letter-spacing: -1.8px;
          }
        }
      }

      grid-template-columns: repeat(1, 1fr);
    }
  }

  @if ($TYPE == "tcl") {
    font-size: 1.4rem;

    .tcl-infobox {
      padding: 10px;

      .infoboxLines {
        grid-gap: 5px;
        padding: 0;

        &:not(:last-child) {
          padding-bottom: 5px;
        }

        .tclLine {
          padding: 10px 7px;
          min-width: 40px;

          &:hover {
            background: initial;
            cursor: pointer;
          }

          img {
            height: 15px;
          }
        }
      }
    }
  }
}

.tooltip-leaflet {
  padding: 0 !important;
  border-radius: 5px !important;

  &:before {
    top: 22px !important;
  }

  .leaflet-popup-content-wrapper {
    background: transparent;
    box-shadow: none;

    .leaflet-popup-content {
      margin: 0;
    }
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}

.tooltip-leaflet-terminus {
  padding: 4px 5px !important;
  font-weight: 500;

  &:before {
    border: none !important;
  }
}

.tooltip {
  background: #ffffff;
  font-size: 1.1em;
  padding: 10px;

  div {
    align-items: center;
    display: flex;
  }

  img {
    height: 25px;
    padding-right: 10px;
    width: 25px;
  }
}

.leaflet-bus-icon {
  span {
    background-color: white;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    border-radius: 50%;
  }
}

.decorator {
  padding: 2px 0;
  background: white;
  border-radius: 5px;
  border: 1px solid $border-m;
  z-index: 9999 !important;
}

@media screen and (max-width: $break-x-small) {
  .infobox {
    font-size: 1em;

    .infoContent {
      .tclLine {
        min-width: 35px !important;
        padding: 6px 5px !important;

        img {
          height: 13px !important;
        }
      }
    }
  }
}
