@if ($TYPE == "tcl") {
  .tcl-elevation {
    margin: 0 15px 15px;
    background: white;
    border-radius: 5px;

    .share {
      position: absolute;
      top: 38px;
      left: 385px;
      padding: 5px;
      background: url(/assets/images/share.svg) no-repeat center;
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
}

.scroll {
  overflow-y: auto !important;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

::placeholder {
  color: if($TYPE == "tcl", #767676, #cccccc);
}

// Generic classes ... TODO
.mode {
  font-size: 1.1em;
  font-weight: 500;
}

.placeSelected {
  padding: 20px;
}

.itemArrow {
  background: url(/assets/images/itemArrow.svg) no-repeat center;
  height: 20px;
  margin-left: 10px;
  width: 15px;
}

.menuTitle {
  color: $primary;
  font-size: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  &.longer {
    font-size: 1.1em;
  }

  &.routeHead {
    padding: 20px;
  }
}

.print {
  cursor: pointer;
  background: url(/assets/images/pdf.svg) no-repeat center;
  width: 20px;
  height: 20px;
}

.images {
  padding: 5px;
  width: 40px;
  //object-fit: contain;
}

.goToRoute {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: -10px;
  padding: 20px;
  font-style: italic;

  &:hover {
    background: $hover;
  }

  img {
    padding-right: 15px;
    width: 25px;
  }

  &.around {
    margin: 0;
    padding: 0;

    img {
      padding: 0 10px;
    }
  }

  @if ($TYPE == "tcl") {
    margin-top: -20px;
  }
}

.hikingDetails {
  align-items: flex-start;
  display: flex;
  margin-bottom: 20px;

  img {
    padding-right: 10px;
    width: 25px;
  }

  span {
    color: $primary;
    font-weight: 600;
  }

  .hikingPlaceCollapse {
    cursor: pointer;
    min-height: 35px;
  }
}

.hikingDetailsMore {
  cursor: pointer;
  display: flex;
  //justify-content: flex-end;
  margin: -15px 0px 20px 30px;
  text-align: center;

  .arrowDetails {
    //background: $shadow-m;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    padding: 0 5px;

    img {
      width: 15px;
    }
  }
}

.pmr {
  background: url(/assets/images/pmr.svg) no-repeat center;
  height: 15px;
  margin-right: -5px;
  padding-left: 20px;
  width: 15px;
}

// End generic

.board {
  background: transparent;
  border-radius: 10px;
  box-shadow: 5px 5px 10px $shadow-xs;
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 400px;
  z-index: 1;

  &.noModule {
    overflow-y: auto;
    -webkit-transform: translate3d(0, 0, 0); // Fuck Safari
  }

  @if ($TYPE == "tcl") {
    background: #f1f5f5;
    border-radius: 10px;
    width: 440px;
    box-shadow: 0 6px 35px 0 rgba(112, 113, 115, 0.25);
    //margin: 50px 0px 50px 30px;
  }

  .header {
    background: $secondary;
    border-radius: 10px 10px 0 0;
    color: if(luminance($secondary) > 0.5, #333333, #ffffff);
    display: flex;
    height: 70px;

    .back {
      align-items: center;
      background: $primary;
      border-right: 1px solid $border-white-xs;
      border-top-left-radius: 15px;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      padding: 0 15px;
      vertical-align: middle;
      width: 40px;

      img {
        height: 28px;
      }
    }

    .boardTitle {
      align-items: center;
      display: inline-flex;
      padding-left: 10px;
      vertical-align: middle;

      .title {
        color: #ffffff;
        padding-left: 10px;
      }

      .images {
        height: 32px;
      }
    }

    @if ($TYPE == "tcl") {
      padding: 15px;
      background: #f1f5f5;

      .back {
        background: white;
        border-radius: 5px 0 0 5px;
        border-right: 1px solid #e3e4e4;
      }

      .boardTitle {
        background: white;
        flex: 1;
        border-radius: 0 5px 5px 0;

        .menuTitle {
          font-size: 1.6rem;
        }

        .title {
          color: $text;
          text-transform: none;
        }
      }
    }
  }

  .head {
    align-items: center;
    background: $primary;
    border-radius: 10px 10px 0 0;
    color: if(luminance($primary) > 0.5, #333333, #ffffff);
    display: flex;

    img {
      height: 58px;
      padding: 15px;
      width: 60px;
    }

    div {
      font-size: 1.3em;
      font-weight: 500;
      line-height: 1.3em;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .content,
  .subContent {
    background: #ffffff;
    border-radius: 10px;
    flex: 1;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }

    &.noScroll {
      overflow: hidden;
    }

    &.main {
      border-radius: 0 0 10px;
    }

    .itemMenu {
      align-items: center;
      border-bottom: 1px solid $border-xs;
      color: #333333;
      display: flex;
      flex: 1;
      padding: 20px;
      text-decoration: none;

      &:hover {
        background: $hover;
      }

      .itemContent {
        flex: 1;
        padding-left: 20px;
      }
    }

    @if ($TYPE == "tcl") {
      background: #f1f5f5;

      &.main {
        background: transparent;
        margin: 15px;
        box-shadow: 0 5px 22px 0 rgba(112, 113, 115, 0.25);
        border-radius: 5px;
        overflow-y: initial;

        .itemMenu {
          background: white;
          border-bottom: 1px solid #e3e4e4;
          padding: 20px 10px;

          &:hover {
            background: $hover;
          }

          &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          .images {
            height: 25px;
            //object-fit: scale-down;
          }

          .itemContent {
            padding-left: 10px;

            .description {
              padding: 5px 20px 0 0;
              font-size: 1.1em;
              font-weight: 300;
            }
          }

          .itemArrow {
            margin: 0 10px;
          }
        }

        .menuTitle {
          color: #58585a;
          font-size: 1.6rem;
          font-weight: 500;
          text-transform: none;
        }

        .tclButtons {
          display: flex;
          justify-content: space-around;
          text-align: center;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          .itemMenu {
            justify-content: flex-start;
            flex-direction: column;
            font-size: 0.8em;
            padding: 10px;

            .menuTitle {
              font-size: 1.4rem;
            }

            &:first-child {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              border-bottom-left-radius: 5px;
            }

            &:last-child {
              border-bottom-right-radius: 5px;
            }

            &:not(:last-child) {
              border-right: 1px solid #e3e4e4;
            }
          }

          .images {
            width: 25px;
          }
        }

        .itemPage {
          background: white;

          &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .react-tabs {
        margin: 0 15px 15px;
        background: white;
        border-radius: 5px;

        .menuTitle {
          color: $text;
          text-transform: none;
        }

        .tabList {
          .tab {
            padding: 15px;
            text-transform: none;
          }
        }
      }
    }

    .itemPage {
      display: flex;
      flex: 1;
      font-size: 0.85em;
      justify-content: flex-end;
      padding: 10px 20px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background: $hover;
      }
    }

    &.page {
      padding: 15px;

      .title {
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 1em;
        margin-top: 2em;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  @if ($TYPE == "tcl") {
    .subContent {
      &.main {
        .itemMenu {
          &:first-child {
            border-radius: 5px;
          }
        }
      }
    }
  }
}

@if ($TYPE == "tcl") {
  .subContent {
    margin: 0 15px 15px !important;

    .itemMenu {
      .images {
        height: 35px !important;
      }
    }
  }
}

.form {
  padding: if($TYPE == "tcl", 0 15px 15px, 15px);

  span.paddingTop {
    padding-left: 10px;
    font-style: italic;
    display: block;
  }

  .formNoBottom {
    padding: 15px;
  }

  .inputs {
    //box-shadow: 2px 2px 10px $shadow-xs;
    border: 1px solid $border-xs;
    border-radius: 5px;
    display: flex;

    .input {
      border: none;
      border-radius: 5px;
      flex: 1;
      font-size: 1em;
      margin: 5px 0;
      outline: none;
      padding: 10px;
    }

    .inputsText {
      display: flex;
      flex: 1;
      flex-direction: column;

      .inputCalculation {
        align-items: center;
        display: flex;
        padding-left: 10px;
        position: relative;

        img {
          height: 32px;
          width: 25px;
        }
      }

      .divider {
        background: $border-m;
        height: 1px;
        margin-left: 10px;
      }
    }

    .swap {
      align-self: center;
      background: url(/assets/images/swap.svg) no-repeat center;
      cursor: pointer;
      display: flex;
      height: 25px;
      margin: 10px;
      width: 25px;
    }

    @if ($TYPE == "tcl") {
      border-radius: 5px;
      border: none;
      background: white;

      .input {
        padding: 10px 20px;
      }
    }
  }

  .represents {
    display: flex;
    margin: 5px 0 5px 5px;
    padding: initial !important;

    .radio {
      margin-right: 15px;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      input {
        margin-right: 8px;
        margin-bottom: 5px;

        &:after {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          top: -2px;
          left: -3px;
          position: relative;
          background-color: #d1d3d1;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 2px solid white;
        }

        &:checked {
          &:after {
            background-color: $primary;
          }
        }
      }
    }
  }

  .prefs {
    margin: 15px 0px -5px;
    display: flex;
    align-self: flex-start;
    flex-direction: column;

    .buttons {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
    }

    .collapse {
      background: white;
      padding: 8px 10px;
      border-radius: 5px;
      border: if($TYPE == "tcl", none, 1px solid $border-xs);
      cursor: pointer;
      display: flex;
      min-width: if($TYPE == "tcl", 150px, 110px);
      position: relative;

      &.date {
        width: 220px;
        margin-right: 15px;
      }

      span {
        padding: 0 0 0 5px;
        color: $primary;
        font-weight: 600;
      }

      .itemArrow {
        position: absolute;
        right: 10px;
        top: 6px;
        transition: all ease-in 500ms;

        &.active {
          transform: rotate(90deg);
        }
      }
    }
  }

  .modes {
    margin-top: 5px;

    label {
      align-items: flex-start;
    }

    @if ($TYPE == "tcl") {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 15px;
      margin: 5px 2px 10px 2px;

      .mode {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
        background: #e6e6e6;
        border-radius: 5px;
        color: #a5a6a7;
        font-size: 0.9em;
        font-weight: 400;
        cursor: pointer;

        &.active {
          background: white;
          box-shadow: 0 0 5px $shadow-m;
        }

        img {
          height: 30px;
          padding: 5px 0;
        }
      }
    }
  }

  .pickers {
    display: flex;
    align-items: center;
  }

  .error,
  .warning {
    margin: 10px 0 0;
  }
}

.loading {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 10px 10px;

  img {
    padding-bottom: 10px;
  }
}

.button {
  background: $primary;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  padding: 15px;
  text-align: center;
}

.autocomplete {
  background: #ffffff;
  box-shadow: 2px 4px 10px $shadow-xs;
  margin-top: 48px;
  max-height: 400px;
  overflow-y: auto;
  position: fixed;
  width: 370px;
  z-index: 999;

  &.offsetRoute {
    left: 35px;
    width: 320px;
  }

  @if ($TYPE == "tcl") {
    width: 410px;

    &.offsetRoute {
      left: 15px;
      width: 365px;
    }
  }

  .item {
    align-items: center;
    display: flex;
    padding: 2px 10px;

    @if ($TYPE == "tcl") {
      font-size: 0.9em;
      font-weight: 500;
    }

    & img {
      padding: 5px 0;
      margin-right: 10px;
    }
  }

  .itemHighlight {
    background: $hover;
    cursor: pointer;
  }
}

.journeys {
  overflow: auto;
  padding: 0 15px;

  .soft {
    .softItems {
      display: flex;
      padding-top: 5px;
      margin-bottom: 15px;

      .softItem {
        cursor: pointer;
        display: flex;
        align-items: center;
        background: #f9f9f9;
        flex: 1;
        border-radius: 4px;
        max-width: 50%;

        &:nth-child(1) {
          margin-right: 5px;
        }

        &:nth-child(n + 2) {
          margin-left: 5px;
        }

        &:hover {
          background: white;
          box-shadow: 0 0 15px $shadow-xs;
        }

        .rootIcon {
          min-width: 20px;
          justify-content: center;
        }

        .softInfos {
          padding: 15px;
          border-left: 1px solid $border-xs;
        }

        @if ($TYPE == "tcl") {
          .rootIcon {
            border: 2px solid $border-xs;
            border-radius: 5px;
            padding: 10px 5px;
            margin: 5px;
          }

          .softInfos {
            border: none;
          }
        }
      }
    }
  }

  .transport {
    margin-bottom: 20px;

    .noSolutionTransport {
      padding: 10px 0 0;
    }

    .journey {
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0 0 15px $shadow-xs;
      color: #333333;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin: 15px 0;

      .journeyHead {
        border-bottom: 1px solid $border-xs;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .time {
          white-space: nowrap;
        }

        .walk,
        .co2 {
          display: flex;
          align-items: center;
          font-size: 0.9em;
        }

        div {
          padding: 10px;

          &:not(:last-child) {
            border-right: 1px solid $border-xs;
          }

          &:first-child {
            flex: 1;
            text-align: center;
          }
        }
      }

      .journeyContent {
        align-items: center;
        display: flex;
        position: relative;

        .journeyTime {
          border-right: 1px solid $border-xs;
          padding: 10px;
          min-width: 60px;
          min-height: 55px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .journeySections {
          align-items: center;
          display: flex;
          flex: 1;
          overflow: auto;
          padding: 20px 50px 20px 15px;
          white-space: nowrap;

          .section {
            padding: 0 2px;
          }

          .journeyOffset {
            min-height: 1px;
            padding: 0 20px;
          }
        }

        .journeySectionsScroll {
          background: linear-gradient(to left, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
          border-bottom-right-radius: 10px;
          padding: 24px 10px 24px 25px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
        }
      }

      &:not(.active) {
        background: $hover;
        border: none;
        box-shadow: none;

        .journeySectionsScroll {
          background: linear-gradient(to left, $hover 50%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }
  }

  @if ($TYPE == "tcl") {
    padding: 0 15px 0;

    .journey {
      .journeyHead {
        color: #888888;

        .time {
          text-align: initial !important;

          .lightBold {
            color: $primary;
            font-weight: 600;
          }
        }

        .leaf {
          height: 15px;
        }
      }

      .journeyContent {
        .journeyTime {
          min-height: 70px !important;

          span {
            font-size: 1.8em !important;
          }
        }

        .journeySections {
          padding: 20px 50px 20px 15px !important;

          .tclLine {
            border: none;

            &:hover {
              background: none;
            }
          }

          .delimiter {
            color: #cccccc;
            font-weight: 600;
          }
        }
      }

      &:not(.active) {
        background: white !important;
        box-shadow: 2px 2px 8px $shadow-xxs !important;

        .journeySectionsScroll {
          background: linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%) !important;
        }
      }
    }

    .softItems {
      padding-top: 0 !important;
      //justify-content: space-between;

      .softItem {
        background: white !important;
        box-shadow: 0 0 5px $shadow-xxs;
        max-width: 33% !important;
        margin: 0 !important;

        &:not(:last-child) {
          margin-right: 5px !important;
        }

        .softInfos {
          padding: 10px !important;

          .time {
            color: $primary;
            font-weight: 600;
            font-size: 1.1em;
          }
        }
      }
    }
  }
}

.printLink {
  color: $primary;
  cursor: pointer;
}

.staticMap {
  display: none;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.roadmap {
  overflow-y: auto;
  padding: 5px 20px 20px;

  .segmentTop,
  .segmentBottom,
  .segmentContent {
    display: grid;
    cursor: pointer;
    grid-template-columns: 60px 40px 1fr;

    .segmentTime {
      font-weight: bold;
      text-align: center;
    }

    .segmentBorder {
      position: relative;

      .segmentBull {
        background: #ffffff;
        border: 3px solid #333333;
        border-radius: 50%;
        height: 10px;
        left: 12px;
        position: absolute;
        top: 0;
        width: 10px;
      }
    }

    .segmentSectionType {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-bottom: 20px;

      .rootIcon {
        margin-left: 100px;
        background: white;
      }
    }

    .segmentDetails {
      padding: 10px 0 20px;

      .little {
        font-size: 0.9em;
      }

      strong {
        font-weight: 500;
      }

      .details {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 0.9em;
        margin-bottom: 5px;

        .detailsCollapse {
          color: $primary;
          font-weight: 500;
          padding-right: 10px;
        }

        img {
          height: 20px;
          padding: 0 5px;
          width: 15px;
        }

        @if ($TYPE == "tcl") {
          color: $primary;
          font-weight: 500;
        }
      }

      .collapse {
        margin-left: -40px;

        .innerStops {
          font-weight: 400;
          margin-left: 40px;
          padding: 2px 0;
          position: relative;
          font-size: 0.9em;
          color: $text;
          margin-bottom: 5px;

          .innerStopBull {
            background: #ffffff;
            border: 2px solid #576369;
            border-radius: 50%;
            height: 6px;
            left: -25px;
            position: absolute;
            top: 5px;
            width: 6px;
          }
        }
      }

      .path {
        font-size: 0.85em;

        .walkDirection {
          img {
            height: 20px;
            vertical-align: -5px;
          }
        }
      }
    }
  }

  @if ($TYPE == "tcl") {
    padding: 20px;
  }
}

.tabList {
  border-bottom: 1px solid $border-xs;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  .tab {
    cursor: pointer;
    flex: 1;
    margin-bottom: -2px;
    outline: none;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    position: relative;

    span {
      font-size: 0.8em;
      opacity: 0.6;
    }

    &.active {
      &:after {
        position: absolute;
        content: "";
        height: 5px;
        width: 36px;
        background-color: $primary;
        left: 50%;
        margin-left: -18px;
        bottom: -1px;
        border-radius: 8px / 5px;
        z-index: 1;
      }
    }
  }
}

.tabPanel {
  display: none;

  .empty {
    padding: 20px;
  }

  &.active {
    display: block;
  }
}

.group {
  cursor: pointer;
  padding: 15px 20px 5px;

  .groupCarpool {
    padding: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border-xs;
  }

  .groupOffsetBottom {
    margin-bottom: 15px;
  }

  .place {
    cursor: pointer;
    padding-bottom: 10px;
    padding-left: 25px;

    &:before {
      content: "\2022 ";
      font-size: 1.4em;
      padding: 0 5px;
    }

    &:hover {
      background: $hover;
    }
  }
}

.groupName {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .groupHead {
    align-items: center;
    display: flex;
    flex: 1;
    font-size: 1.1em;

    img {
      // height: 40px;
      padding-right: 10px;
      width: 30px;
      object-fit: cover;
    }

    .hikingPlace {
      display: flex;
      flex-direction: column;

      img {
        margin-top: 10px;
        padding-right: 5px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .address {
    font-size: 0.9em;
  }

  .arrowGroup {
    display: flex;
    margin-right: -5px;

    img {
      height: 25px;
      transition: all ease-in 500ms;
      width: 25px;

      &.closed {
        transform: rotate(-90deg);
      }
    }
  }
}

@if ($TYPE == "tcl") {
  .tcl-mftrx {
    .lines {
      margin: 10px 0;
    }
  }
}

.walk,
.bss,
.bike,
.leaf {
  height: 18px;
}

.lines {
  display: grid;
  text-align: center;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @if ($TYPE == "tcl") {
    grid-template-columns: repeat(4, 1fr);
  }
}

.tad-lines {
  grid-template-columns: repeat(2, 1fr);
}

@if ($TYPE == "tcl") {
  .tclLine {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 7px;
    border: 2px solid $border-xs;
    border-radius: 5px;

    &:hover {
      background: $hover;
    }

    img {
      height: 20px;
      max-width: 65px;
    }
  }
}

.lineLabel {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 70px;
}

.lineCode {
  border-radius: 2px;
  cursor: pointer;
  font-weight: 500;
  min-width: 70px;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.line {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;

  .highlight {
    background: $hover;
  }

  .lineCode {
    flex: 1;
  }

  .autocompleteDirection {
    font-size: 0.95em;
    font-weight: 600;
  }
}

.longLineCode {
  letter-spacing: -1.5px;
}

.leftLines {
  display: flex;
  flex-direction: column;
}

.selectedLines {
  border-bottom: 1px solid $border-m;
  display: flex;
  flex-direction: column;
  padding: 20px 10px 15px 20px;

  .lightBold {
    font-weight: 500 !important;
  }
}

.currentLine {
  .leftLine {
    border-bottom: 1px solid $border-m;
  }

  .stops {
    overflow: auto;
    padding: 10px;

    .stop {
      cursor: pointer;
      display: flex;

      .border {
        border-left: 4px solid;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-left: 18px;
        margin-top: 9px;

        @if ($TYPE == "tcl") {
          margin-left: 25px;
        }
      }

      &:not(:first-child) .border {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
      }

      &:last-child .border {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 17px;
      }

      .point {
        background: #ffffff;
        border-radius: 50%;
        height: 6px;
        margin: 10px 35px 0 -7px;
        width: 6px;
      }

      .pointTerminus {
        height: 12px;
        margin: 8px 32px 0 -10px;
        width: 12px;
      }

      .stopName {
        flex: 1;
        padding: 2px 0 10px;

        .connections {
          display: flex;

          .connection {
            border-radius: 2px;
            font-size: 0.7em;
            font-weight: 500;
            margin: 0 3px 10px 0;
            padding: 2px 4px;
          }
        }

        &.selected {
          margin-left: -10px;

          .connections {
            padding-left: 10px;
          }

          .selectedStop {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: -3px;
            padding-left: 10px;

            .stopActions {
              display: flex;
            }

            .lightBold {
              font-weight: 600;
            }
          }

          .selectedContent {
            display: flex;
            flex-direction: column;
            cursor: default;

            .schedules {
              padding: 0 10px 0;

              .nextSchedules {
                //font-style: italic;
                font-size: 0.9em;
                padding: 0 0 5px;
              }

              .schedule {
                align-items: center;
                display: flex;
                font-weight: 600;
                padding: 2px 0;

                img {
                  height: 12px;
                  margin: 0 5px;
                }
              }
            }

            .seeTimetable {
              align-items: center;
              border-radius: 10px;
              cursor: pointer;
              display: flex;
              font-size: 0.9em;
              padding: 10px;
              margin-right: 5px;

              & img {
                height: 25px;
                padding-right: 10px;
              }

              &:hover {
                background: $hover;
              }
            }

            @if ($TYPE == "tcl") {
              justify-content: space-between;
              align-items: center;
              flex-direction: row;

              .schedule {
                font-weight: initial !important;
                font-size: 0.9em;
                padding: 5px 0 !important;
              }

              .seeTimetable {
                border: 2px solid $border-xs;
                border-radius: 5px;
                font-size: 0.85em;
              }
            }
          }
        }
      }
    }
  }

  .timetableStop {
    border-bottom: 1px solid $border-m;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bold {
      font-size: 1em;
      font-weight: 600;
    }
  }

  .timetable {
    padding: 0 20px 20px;

    .timetableHead {
      cursor: pointer;
      display: flex;
      padding-bottom: 15px;
    }

    .timetableSlide {
      width: 100%;
      .timetableBodyHours {
        height: 24px;
        padding-top: 0px;
      }
      .timetableBodyMinutes {
      }
      .timetableBody {
        display: flex;
        flex: 1;
        .timetableColumn {
          align-items: center;
          background: #ffffff;
          display: flex;
          flex: 1;
          flex-direction: column;

          &:nth-child(even) {
            background: #f4f4f4;
          }

          .timetableHour {
            color: $primary;
            font-weight: 600;
            font-weight: 600;
            padding: 5px 0;
          }

          .timetableMinutes {
            padding: 2px 0;

            .timetableMinutesLetter {
              color: red;
              font-size: 0.9em;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  .otherDirections {
    padding: 10px;
    .otherdirectionsContent {
      padding: 2px 0;
      .otherdirectionsContentLetter {
        color: red;
        font-weight: 300;
        font-size: 0.9em;
      }
    }
  }
}

.error,
.warning {
  align-items: center;
  background: $error;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  margin: 10px 20px;
  padding: 10px;

  img {
    height: 30px;
    margin-right: 10px;
  }
}

.warning {
  background: $warning;
}

.line,
.leftLine {
  .tad {
    background: url(/assets/images/tad.svg) no-repeat center;
    width: 75px;
    height: 35px;
    background-size: cover;
    border-radius: 2px;
    border: 1px solid $border-m;
  }

  .tad-0 {
    width: 100%;
    height: 40px;
    background: url(/assets/images/tad-0.svg) no-repeat center;
  }

  .tad-1 {
    width: 100%;
    height: 40px;
    background: url(/assets/images/tad-1.svg) no-repeat center;
  }
}

.leftLine {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  padding: 10px;
  position: relative;

  .timetableOptions {
    min-width: 100px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    background: white;
    box-shadow: -2px 3px 10px $shadow-xs;

    .timetableOptionsItem {
      text-align: center;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: $hover;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $border-xs;
      }
    }
  }

  .direction {
    flex: 1;
    font-size: 0.85em;
    margin-left: 10px;

    &.rer {
      font-size: 1.1em;
    }

    .tadLink {
      display: block;
      text-decoration: none;
      font-size: 1.2em;
      color: $primary;
      font-weight: 500;
      margin-top: 10px;
    }

    .lightBold {
      font-weight: 600;
      font-size: 1.2em;
    }
  }

  .tools {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.toolSwap,
.toolTimetable,
.toolRemove,
.toolRouteCalculation,
.toolFavorite {
  cursor: pointer;
  height: 18px;
  margin: 3px;
  width: 25px;

  &.toolSmall {
    height: 15px;
    margin: 0;
  }
}

.toolFavorite {
  height: 25px;
}

.toolSwap {
  background: url(/assets/images/swap.svg) no-repeat center;
}

.toolTimetable {
  background: url(/assets/images/pdf.svg) no-repeat center;
  display: block;
}

.toolRemove {
  background: url(/assets/images/removeLine.svg) no-repeat center;
}

.toolRouteCalculation {
  background: url(/assets/images/route-calculation.svg) no-repeat center;

  @if ($TYPE == "tcl") {
    background: none;
    padding: 5px;
    border: 1px solid $border-m;
    border-radius: 5px;
    display: flex;
    justify-content: center;

    &:hover {
      background: $hover;
    }
  }
}

.toolFavorite {
  background: url(/assets/images/favorite.svg) no-repeat center;
}

.rootIcon {
  border-radius: 20px;
  color: if(luminance($primary) > 0.5, #333333, #ffffff);
  display: flex;
  align-items: center;
  height: 18px;
  padding: 8px 5px;

  .car {
    height: 16px !important;
  }

  .icon {
    align-self: center;
    background: transparent;
    height: 100%;
  }
}

// Media Queries
@media screen and (max-width: $break-small) {
  .board {
    flex: 1;
    margin: 0;
    width: 100%;
    overflow-y: scroll;

    .header {
      height: 60px;

      .back {
        width: 35px;

        img {
          height: 28px;
        }
      }

      .boardTitle {
        .images {
          height: 20px;
          width: initial;
        }

        .menuTitle {
          font-size: 1.1em;
        }
      }

      @if ($TYPE == "tcl") {
        .boardTitle {
          .images {
            height: 28px;
          }
        }
      }
    }

    .head {
      img {
        height: 40px;
      }

      div {
        font-size: 1em;
      }
    }

    .content {
      @if ($TYPE != "tcl") {
        border-radius: 0;
      }

      .form {
        .prefs {
          margin: 10px 0 0;

          .modes {
            margin: 10px 2px 0;
            grid-gap: 10px;
          }
        }

        .buttons {
          flex-direction: column;
          align-items: initial;
          padding-bottom: 0;

          .collapse {
            width: initial !important;
            flex: 1;

            &.date {
              margin: 0 0 10px;
            }
          }
        }

        .inputs {
          .input {
            font-size: 0.9em;
            padding: 5px 10px;
          }
        }

        .autocomplete {
          left: 15px;
          right: 15px;
          margin-top: 35px;
          max-height: 250px;
          width: auto;

          &.offsetRoute {
            width: 80% !important;
          }
        }
      }

      .itemMenu {
        padding: 15px;
      }

      .goToRoute {
        padding: 15px 20px 10px;
        font-size: 0.85em;

        img {
          width: 20px;
        }
      }
    }
  }

  .border {
    @if ($TYPE == "tcl") {
      margin-left: 18px !important;
    }
  }

  .timetableStop {
    padding: 10px 15px !important;
  }

  .timetable {
    .timetableSlide {
      width: 100%;
    }
  }

  .placeSelected {
    padding: 10px 20px;
  }

  @if ($TYPE == "tcl") {
    .board {
      .content {
        &.main {
          margin: 10px;
        }
      }

      .subContent {
        &.main {
          margin: 0 10px 10px !important;
        }
      }

      .header {
        padding: 10px !important;

        .boardTitle {
          padding-left: 5px;
        }
      }

      .tcl-elevation {
        margin: 0 10px 10px;

        .group {
          padding: 10px;

          .groupName {
            margin-bottom: 0;
          }

          .groupOffsetBottom {
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
      }

      .form {
        padding: 0 10px 10px;

        .button {
          margin-top: 10px;
        }
      }

      .journeys {
        padding: 5px 10px 0;

        .journey {
          margin: 10px 0;

          .journeyTime {
            padding: 0 10px !important;
          }
        }

        .soft {
          .time {
            white-space: nowrap;
          }
        }

        .transport {
          margin-bottom: 0;
        }
      }

      .roadmap {
        padding: 20px 10px 10px !important;

        .segmentTop,
        .segmentBottom,
        .segmentContent {
          grid-template-columns: 45px 40px 1fr;

          .segmentBull {
            left: 12px;
          }

          .innerStopBull {
            left: -25px !important;
          }

          .rootIcon {
            margin-left: 85px;
          }
        }
      }
    }

    .tclLine {
      padding: 7px !important;

      img {
        height: 15px;
        max-width: 50px;
      }
    }

    .share {
      top: 28px !important;
      left: initial !important;
      right: 25px;
    }
  }
}

@media screen and (max-width: $break-x-small) {
  .board {
    .content,
    .subContent {
      .itemMenu {
        padding: 10px !important;
      }

      .header {
        height: 45px;

        .back {
          width: 25px;

          img {
            height: 20px;
          }
        }
      }

      .menuTitle {
        font-size: 1em !important;
      }

      .form {
        display: block !important;
      }

      .groupName {
        .mode {
          font-size: 1em !important;
        }
      }

      .modes {
        //grid-template-columns: repeat(3, 1fr);

        .mode {
          font-size: 0.8em;

          img {
            height: 25px;
          }
        }
      }

      .lightBold {
        font-size: 1.1em !important;
      }

      .stop {
        .lightBold {
          font-size: 0.9em !important;
        }

        .seeTimetable {
          font-size: 0.75em !important;
        }
      }

      .timetableStop {
        font-size: 0.9em !important;
      }

      .timetable {
        font-size: 0.9em !important;
      }

      .lines {
        grid-template-columns: repeat(4, 1fr);

        @if ($TYPE == "tcl") {
          //grid-template-columns: repeat(3, 1fr);
        }
      }

      .journeys {
        .softItems {
          .softInfos {
            font-size: 0.9em;
            padding-left: 2px !important;

            .time {
              font-size: 1em;
            }
          }
        }

        .transport {
          .mode {
            font-size: 1em;
          }

          .journey {
            font-size: 0.9em;
          }
        }
      }

      .roadmap {
        padding: 20px 10px;
        font-size: 0.9em;
      }
    }

    .header {
      height: 50px;

      .back {
        img {
          height: 25px;
        }
      }
    }

    .share {
      top: 22px !important;
      right: 20px;
      padding: 3px !important;
    }
  }

  .currentLine {
    .timetableStop {
      padding: 15px 20px;
    }
  }
}

@media print {
  .timetable {
    padding: 0 !important;
    font-size: 13px;
    font-weight: 300; // TODO : Why is this needed on CCVE ?? 400 would be better
    display: flex;
    flex: 1;
    flex-direction: row;

    .otherDirections {
      padding: 20px 10px;
      .otherdirectionsContent {
        padding: 2px 0;
        .otherdirectionsContentLetter {
          color: red;
        }
      }
    }

    .lowBold {
      font-weight: 600;
    }

    .timetableStopDirection {
      padding: 20px 10px;

      .timetableDirection {
        padding-top: 5px;
      }

      .timetableStop {
      }
    }

    .timetableInfo {
      padding: 10px;
      text-align: left;
      display: flex;
      align-items: center;

      @if ($TYPE == "tcl") {
        .tclLine {
          border: none;
          padding: 0 10px 0 0;
        }
      }

      .lineCode {
        display: inline-block;
        height: 23px;
        width: 42px;
        margin-right: 20px;
        vertical-align: center;
        horiz-align: center;
        font-size: 1.4em;
        font-weight: 600;
      }

      .timetableLine {
        display: inline-flex;

        .timetableDirection {
          text-align: left;
        }
      }
    }

    .timetableTitle {
      padding: 25px;
      text-align: center;
    }

    .timetableHead {
      cursor: pointer;
      display: flex;
      padding-bottom: 15px;
    }

    .timetableContent {
      display: flex;
      .timetableSlide {
        .otherDirections {
          // don't display otherdirections on every Slide
          display: none;
        }

        .timetableBody {
          display: inline-flex;
          flex: 1;
          align-items: flex-start;

          .timetableColumn {
            align-items: center;
            background: #ffffff;
            display: flex;
            min-width: 25px;
            flex: 1;
            flex-direction: column;
            padding: 0 3px;

            &.printHide {
              display: none;
            }

            &:nth-child(even) {
              background: #f4f4f4;
            }

            .timetableHour {
              color: $primary;
              font-weight: 600;
              padding-bottom: 8px;
            }

            .timetableMinutes {
              display: flex;
              font-size: 1em;
              font-weight: 300;
              padding-top: 3px;
              padding-bottom: 4px;

              .timetableMinutesLetter {
                color: red;
                font-size: 0.9em;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }
}

@if ($TYPE == "tcl") {
  .select-hours__option--is-focused,
  .select-minutes__option--is-focused {
    background: rgba(226, 0, 26, 0.5) !important;
    color: white;
  }

  .select-hours__option--is-selected,
  .select-minutes__option--is-selected {
    background: $primary !important;
  }
}
