$TYPE: "ccve" !default;

$font: if($TYPE == "tcl", "Roboto", "Montserrat");
$font-size: if($TYPE == "tcl", 10px, 1em);

// Default colors
$primary: #005e86;
$secondary: #576369;
$text: #333333;
$cluster: $secondary;
$hover: #f9f9f9;
$error: #da5858;
$warning: #ffc967;

@if ($TYPE == "tcl") {
  $primary: #e2001a;
  $text: #58585a;
}

// Borders
$border-white-xs: rgba(255, 255, 255, 0.2);
$border-xs: rgba(0, 0, 0, 0.08);
$border-m: rgba(0, 0, 0, 0.15);

// Shadows
$shadow-xxs: rgba(0, 0, 0, 0.02);
$shadow-xs: rgba(0, 0, 0, 0.15);
$shadow-m: rgba(0, 0, 0, 0.3);
$shadow-xl: rgba(0, 0, 0, 0.4);

// Breakpoints CSS
$break-small: 600px;
$break-x-small: 360px;
